<template>
	<div
		class="absolute h-screen w-[240px] border-r border-r-grey-light bg-grey-very-light px-5 pb-5"
	>
		<div class="flex h-16 items-center">
			<NuxtLink to="/">
				<IconsLogoBlack
					class="w-36 transition duration-200 ease-in-out hover:scale-[103%] active:scale-100"
				/>
			</NuxtLink>
		</div>

		<div class="mt-5 flex flex-col gap-7">
			<NuxtLink to="/dashboard" class="link group"
				><HomeIcon class="icon" />Dashboard</NuxtLink
			>
			<NuxtLink
				v-if="forecastAllowedUserIds.includes(user.id)"
				to="/dashboard/reporting/forecasts"
				class="link group"
				><ChartBarSquareIcon class="icon" />Forecasts</NuxtLink
			>
			<ManagerAndAdminOnly>
				<NuxtLink to="/dashboard/theme" class="link group"
					><PaintBrushIcon class="icon" />Theme</NuxtLink
				></ManagerAndAdminOnly
			>
			<ManagerAndAdminOnly>
				<NuxtLink to="/dashboard/media" class="link group"
					><PhotoIcon class="icon" />Media</NuxtLink
				></ManagerAndAdminOnly
			>
			<ManagerAndAdminOnly>
				<NuxtLink to="/dashboard/pages" class="link group"
					><DocumentDuplicateIcon class="icon" />Pages</NuxtLink
				></ManagerAndAdminOnly
			>
			<ManagerAndAdminOnly>
				<NuxtLink to="/dashboard/products" class="link group"
					><ShoppingBagIcon class="icon" />Products</NuxtLink
				></ManagerAndAdminOnly
			>

			<NuxtLink to="/dashboard/menu" class="link group"
				><PrinterIcon class="icon" />Menu</NuxtLink
			>

			<ManagerAndAdminOnly>
				<NuxtLink to="/dashboard/reviews" class="link group"
					><ChatBubbleOvalLeftEllipsisIcon class="icon" />Reviews</NuxtLink
				></ManagerAndAdminOnly
			>
			<ManagerAndAdminOnly>
				<NuxtLink to="/dashboard/jobs" class="link group"
					><BriefcaseIcon class="icon" />Jobs</NuxtLink
				></ManagerAndAdminOnly
			>
			<AdminOnly>
				<NuxtLink to="/dashboard/users" class="link group"
					><UsersIcon class="icon" />Users</NuxtLink
				></AdminOnly
			>
			<NuxtLink
				v-if="user.id === '60902ed8-505e-4dfc-83c4-2194964e53a5'"
				to="/dashboard/web-vitals"
				class="link group"
				><BoltIcon class="icon" />Web Vitals</NuxtLink
			>
			<AdminOnly>
				<NuxtLink to="/dashboard/settings" class="link group"
					><Cog8ToothIcon class="icon" />Settings</NuxtLink
				></AdminOnly
			>
		</div>
		<div class="absolute bottom-4 text-xs text-zinc-500">App Version {{ version }}</div>
	</div>
</template>

<script setup>
import {
	BoltIcon,
	BriefcaseIcon,
	ChartBarSquareIcon,
	ChatBubbleOvalLeftEllipsisIcon,
	Cog8ToothIcon,
	DocumentDuplicateIcon,
	HomeIcon,
	PaintBrushIcon,
	PhotoIcon,
	PrinterIcon,
	ShoppingBagIcon,
	UsersIcon,
} from '@heroicons/vue/24/outline';
import AdminOnly from './dashboard/access control/AdminOnly.vue';
import ManagerAndAdminOnly from './dashboard/access control/ManagerAndAdminOnly.vue';
import { version } from '../package.json';

const user = useSupabaseUser();

const forecastAllowedUserIds = [
	'60902ed8-505e-4dfc-83c4-2194964e53a5', // Dennis
	'95db9d53-0c87-4022-8789-4e88d59f43ad', // Alec
	'd075c9a8-446a-4138-b6cd-aff00616efde', // Jarred
	'f225a5f0-f8cc-4db8-ba68-101558a91440', // Blake
	'e3d33baf-c1ad-4d49-ac93-2545d2065892', // Van
];
</script>

<style scoped>
.link {
	@apply flex cursor-pointer items-center text-zinc-600 transition duration-200 hover:text-zinc-900;
}
.icon {
	@apply mr-3 w-5 stroke-slate-400 transition duration-200 group-hover:stroke-slate-600;
}
</style>
